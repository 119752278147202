<template>
  <b-sidebar id="sidebar-dz" no-header :visible="value" @hidden="$emit('input', null)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t('service.helicopter.bases.add_a_deposit_zone') }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer ref="depositZoneFormRef" tag="form" class="p-5" @submit.prevent="onSubmit">
        <div style="font-size: 13px">{{ $t('service.helicopter.bases.city_base') }} :</div>
        <div v-if="baseOfDepositZone" class="ml-50 mb-2">{{ baseOfDepositZone.city }}</div>

        <!-- ADDRESS -->
        <validation-provider #default="{ errors }" :name="$t('common.address')" rules="required" class="validation-required">
          <b-form-group label-for="address" :label="$t('common.address')">
            <b-form-input id="address" v-model="address.address" :state="errors[0] ? false : null" :placeholder="$t('common.address')" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Postalcode -->
        <validation-provider #default="{ errors }" :name="$t('address.postal_code')" rules="required" class="validation-required">
          <b-form-group label-for="postalCode" :label="$t('address.postal_code')">
            <b-form-input id="postalCode" v-model="address.postalCode" :state="errors[0] ? false : null" :placeholder="$t('address.postal_code')" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- City -->
        <validation-provider #default="{ errors }" :name="$t('address.city')" rules="required" class="validation-required">
          <b-form-group label-for="city" :label="$t('address.city')">
            <b-form-input id="city" v-model="address.city" :placeholder="$t('address.city')" :state="errors[0] ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Country -->
        <enum-select v-model="address.countryCode" :label="$t('common.country')" text="text" enum-class="CountryCode" required />
        <!-- submit and reset -->
        <app-form-actions class="mt-5" form-name="deposit-zone-form" @cancel="hide" @reset="address = _cloneDeep(initAddress)" />
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent, inject, ref, watch } from '@vue/composition-api'
import { BSidebar } from 'bootstrap-vue'

import AppFormActions from '@/components/AppFormActions.vue'

export default defineComponent({
  name: 'DepositZoneForm',

  components: {
    BSidebar,

    AppFormActions,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    baseOfDepositZone: {
      type: Object,
      default: null,
    },
    depositZone: {
      type: Object,
      default: null,
    },
  },

  setup(props, ctx) {
    const { $i18n, toaster, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    const depositZoneFormRef = ref(null)
    const dzLoader = ref(false)

    const address = ref({
      baseId: null,
      address: '',
      postalCode: '',
      city: '',
      countryCode: '',
    })

    const initAddress = _cloneDeep(address.value)

    watch(
      () => props.value,
      value => {
        if (!value) {
          address.value = _cloneDeep(initAddress)
          depositZoneFormRef.value.reset()
        } else {
          address.value.baseId = props.baseOfDepositZone.id
        }
      },
    )

    watch(
      () => props.depositZone,
      depositZone => {
        if (depositZone) {
          address.value = {
            ...address.value,
            address: depositZone ? depositZone.address : '',
            postalCode: depositZone ? depositZone.postalCode : '',
            city: depositZone ? depositZone.city : '',
            countryCode: depositZone ? depositZone.countryCode : '',
          }
        }
      },
      {
        immediate: true,
      },
    )

    const onSubmit = async () => {
      await depositZoneFormRef.value.validate().then(validForm => {
        if (validForm) {
          dzLoader.value = true

          if (props.depositZone) {
            product.value
              .patchDepositZone(address.value, props.baseOfDepositZone.id, props.depositZone.id)
              .then(() => {
                $emit('input', null)
                address.value = _cloneDeep(initAddress)
                product.value.fetchBases()
              })
              .finally(() => {
                dzLoader.value = false
              })
          } else {
            product.value
              .postDepositZone(address.value)
              .then(() => {
                $emit('input', null)
                address.value = _cloneDeep(initAddress)
                product.value.fetchBases()
              })
              .finally(() => {
                dzLoader.value = false
              })
          }
        } else {
          toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })
    }

    return {
      product,
      address,
      initAddress,
      depositZoneFormRef,
      dzLoader,
      onSubmit,
    }
  },
})
</script>
