<template>
  <b-sidebar id="sidebar-base" no-header :visible="!!value" @hidden="$emit('input', null)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t(`service.helicopter.bases.${value}_a_city`) }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <validation-observer ref="baseFormRef" tag="form" class="p-2" @submit.prevent="onSubmit">
        <b-row>
          <!-- ADDRESS -->
          <b-col cols="12">
            <app-input id="address" v-model="address.cityBase.address" :label="$t('common.address')" required />
          </b-col>

          <!-- POSTAL CODE -->
          <b-col cols="12">
            <app-input id="postalCode" v-model="address.cityBase.postalCode" :label="$t('address.postal_code')" required />
          </b-col>

          <!-- CITY -->
          <b-col cols="12">
            <app-input id="city" v-model="address.cityBase.city" :label="$t('address.city')" required />
          </b-col>
        </b-row>

        <!-- Country -->
        <enum-select
          v-model="address.cityBase.countryCode"
          :label="$t('common.country')"
          text="text"
          enum-class="CountryCode"
          required
        />

        <!-- Active -->
        <b-form-checkbox v-model="address.active" switch inline>
          {{ $t('common.active') }}
        </b-form-checkbox>

        <!-- Submit and reset -->
        <app-form-actions class="mt-5" form-name="base-form" :show-reset="value !== 'edit'" @cancel="hide" @reset="address = _cloneDeep(initAddress)" />
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent, inject, ref, watch } from '@vue/composition-api'

import AppFormActions from '@/components/AppFormActions.vue'

export default defineComponent({
  name: 'BasesForm',

  components: {
    AppFormActions,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    selectedBase: {
      type: Object,
      default: null,
    },
  },

  setup(props, ctx) {
    const { $i18n, toaster, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    const baseFormRef = ref(null)
    const baseLoading = ref(false)

    const address = ref({
      id: null,
      active: true,
      cityBase: {
        address: '',
        postalCode: '',
        city: '',
        countryCode: '',
      },
    })
    const initAddress = _cloneDeep(address.value)

    watch(
      () => props.value,
      value => {
        if (value === 'edit') {
          address.value = _cloneDeep(props.selectedBase)
        } else if (!value) {
          baseFormRef.value.reset()
          address.value = _cloneDeep(initAddress)
        }
      },
    )

    const onSubmit = async () => {
      await baseFormRef.value.validate().then(validForm => {
        if (validForm) {
          baseLoading.value = true
          const action = props.value === 'add' ? product.value.postBase : product.value.patchBase

          action(address.value)
            .then(response => {
              if (undefined === response) {
                return
              }

              $emit('input', null)
              address.value = initAddress
              product.value.fetchBases()
            })
            .finally(() => {
              baseLoading.value = false
            })
        } else {
          toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })
    }

    return {
      product,
      address,
      initAddress,
      baseFormRef,
      baseLoading,
      onSubmit,
    }
  },
})
</script>
